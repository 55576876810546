<template>
  <div>
    <!--begin::User-->
    <div>
          <h3 class="card-label m-0">
            {{ $t('installment.installment') }}
            <span class="text-muted pt-2 font-size-sm d-block"></span>
          </h3>

      <div class="row">
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <p>{{ $t('installment.client_no') }}: {{ invoice.client_no }}</p>
                <p>{{ $t('installment.client_name') }}: {{ invoice.client_name }}</p>
                <p>{{ $t('installment.client_email') }}: {{ invoice.client_email }}</p>
              </div>
            </div>
          </div>
          <div class="card card-custom mt-5">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('installment.installment_account') }}<span class="text-danger">*</span></label>
                <input type="number" v-model="data.installment_account" min="0" class="form-control"
                       :class="validation && validation.installment_account ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.installment_account"
                      class="fv-plugins-message-container invalid-feedback">
                                {{ validation.installment_account[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('installment.period_installment') }}<span class="text-danger">*</span></label>
                <select name="" id="period_installment" v-model="data.period_installment" class="custom-select"
                        :class="validation && validation.period_installment ? 'is-invalid' : ''">
                  <option v-for="row in period_installment_list" :value="row.id" :key="row.id">{{ row.title }}</option>
                </select>
                <span v-if="validation && validation.period_installment"
                      class="fv-plugins-message-container invalid-feedback">
                                {{ validation.period_installment[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('installment.installment_start_date') }}<span class="text-danger">*</span></label>
                <input type="date" v-model="data.installment_start_date" min="0" class="form-control"
                       :class="validation && validation.installment_start_date ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.installment_start_date"
                      class="fv-plugins-message-container invalid-feedback">
                                {{ validation.installment_start_date[0] }}
                            </span>
              </div>
            </div>
          </div>

        </div>
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('installment.invoice_no') }}<span class="text-danger">*</span></label>
                <input type="text" :value="data.invoice_id" readonly class="form-control"
                       :class="validation && validation.invoice_id ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.invoice_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.invoice_id[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('installment.installment_agreement_amount') }}</label>
                <input type="number" :value="invoice.installment_agreement_amount" :disabled="isDisabled" readonly
                       class="form-control"/>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('installment.installment_amount') }}<span class="text-danger">*</span></label>
                <input type="number" v-model="data.installment_amount" min="0" class="form-control"
                       :class="validation && validation.installment_amount ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.installment_amount"
                      class="fv-plugins-message-container invalid-feedback">
                                {{ validation.installment_amount[0] }}
                            </span>
              </div>

              <div class="col-lg-6 mb-5">
                <label>{{ $t('installment.currency') }}<span class="text-danger">*</span></label>
                <div class="input-group mb-3">
                  <select name="" id="currency_id" :disabled="isDisabled" v-model="data.currency_id" class="custom-select"
                          :class="validation && validation.currency_id ? 'is-invalid' : ''">
                    <option v-for="row in currencies" :value="row.id" :key="row.id">{{ row.name }}</option>
                  </select>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/settings/currencies" target="_blank"><i class="fa fa-plus"
                                                                                              style="padding: 0"></i></a>
                  </div>
                </div>
                <span v-if="validation && validation.currency_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.currency_id[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('exchange_rate') }}</label>
                <input type="number" v-model="data.exchange_rate" step="0.01" min="0" class="form-control"
                       :class="validation && validation.exchange_rate ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.exchange_rate" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.exchange_rate[0] }}
                                </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('status') }}</label>
                <select name="" id="status" v-model="data.status" class="custom-select"
                        :class="validation && validation.status ? 'is-invalid' : ''">
                  <option v-for="row in status_list" :value="row.id" :key="row.id">{{ row.title }}</option>
                </select>
                <span v-if="validation && validation.status" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.status[0] }}
                            </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-5">
        <b-tabs content-class="mt-3" class="nav-custom-link">
          <b-tab :title="$t('notes')">
            <div class="card card-custom">
              <div class="card-body row">
                <div class="col-lg-12 mb-5">
                  <label>{{ $t('installment.notes') }}</label>
                  <textarea v-model="data.notes" class="form-control"
                            :class="validation && validation.notes ? 'is-invalid' : ''"></textarea>
                  <span v-if="validation && validation.notes" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.notes[0] }}
                            </span>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>

      <div class="pr-0 pl-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="button" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
          </div>
        </div>
      </div>

    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import {mapGetters, mapState} from "vuex";

export default {
  name: "from-installment",
  data() {
    return {
      mainRoute: 'installmentmanagement/installments',
      mainRouteDependency: 'base/dependency',
      mainRouteInvoice: 'sales/sales_invoices',

      idEdit: this.$route.params.id ? this.$route.params.id : null,


      data: {
        invoice_id: this.$route.params.invoice_id ? this.$route.params.invoice_id : null,
        client_id: null,
        currency_id: null,
        installment_amount: null,
        installment_start_date: null,
        installment_account: null,
        status: 0,
        period_installment: null,
        notes: null,
        exchange_rate: null,
      },
      invoice: {
        installment_agreement_amount: null,
        client_no: null,
        client_name: null,
        client_email: null,
      },
      isEditing: false,
      validation: null,

      currencies: [],
      isDisabled: false,
      period_installment_list: [],
      status_list: [
        {id: 0, title: this.$t('unpaid')},
        {id: 1, title: this.$t('paid')},
      ],
    };
  },
  computed: {
    ...mapState({user_personal_info: state => state.profile.user_personal_info}),
    ...mapGetters(["currentUserPersonalInfo"]),

  },
  watch: {

    'data.installment_account': function (val) {
      if (val) {
        if (this.invoice.installment_agreement_amount) {
          this.data.installment_amount = parseFloat(this.invoice.installment_agreement_amount) / parseInt(val);
          this.data.installment_amount = this.data.installment_amount.toFixed(2);
        }
      }
    }
  },

  methods: {
    async defaultDataForUser() {
      await ApiService.get(this.mainRouteDependency + "/default_data_for_user").then((response) => {
        this.data.currency_id = response.data.data.currency_id;
      });
    },
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {
      ApiService.post(this.mainRoute, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/installment/installment');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    update() {
      ApiService.put(this.mainRoute + '/' + this.idEdit, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/installment/installment');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    getData() {
      ApiService.get(this.mainRoute + '/' + this.idEdit).then((response) => {
        this.isEditing = true;
        this.data.invoice_id = response.data.data.invoice_id;
        this.data.client_id = response.data.data.client_id;
        this.data.currency_id = response.data.data.currency_id;
        this.data.installment_amount = response.data.data.installment_amount;
        this.data.installment_start_date = response.data.data.installment_start_date;
        this.data.installment_due_date = response.data.data.installment_due_date;
        this.data.installment_account = response.data.data.installment_account;
        this.data.status = response.data.data.status;
        this.data.period_installment = response.data.data.period_installment;
        this.data.notes = response.data.data.notes;
        this.data.exchange_rate = response.data.data.exchange_rate;

        if (response.data.data.invoice_id) {
          this.getInvoiceData();
        }
      });
    },
    getInvoiceData() {
      if (this.data.invoice_id) {
        ApiService.get(this.mainRouteInvoice + '/' + this.data.invoice_id).then((response) => {
          // this.isEditing = true;
          this.data.invoice_id = response.data.data.id;
          this.data.client_id = response.data.data.customer_id;
          this.invoice.installment_agreement_amount = response.data.data.invoice_total;
          this.invoice.client_no = response.data.data.customer_id;

          if (response.data.data.customer) {
            this.invoice.client_name = response.data.data.customer.name;
            this.invoice.client_email = response.data.data.customer.email;
          }

        });
      }
    },

    async getSalesInvoiceData() {
      if (this.data.invoice_id) {
        await ApiService.get(`${this.mainRouteInvoice}/${this.data.invoice_id}`).then((response) => {
          this.data.currency_id = response.data.data.currency_id;
          if (this.data.invoice_id && this.data.currency_id) {
            this.isDisabled = true;
          }
        });
      }
    },

    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },
    getPeriodInstallment() {
      ApiService.get(this.mainRouteDependency + "/period_installment").then((response) => {
        this.period_installment_list = response.data.data;
      });
    },

  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: this.$t("MENU.installment"),
      route: '/installment/installment'
    }, {title: (this.idEditing ? this.$t('update') : this.$t('create'))}]);



    this.getCurrencies();
    this.getPeriodInstallment();

    this.getInvoiceData();

    this.data.installment_start_date = new Date().toISOString().slice(0, 10);

    if (this.idEdit) {
      this.getData();
    } else {
      this.defaultDataForUser();
      let promise = this.getSalesInvoiceData();

      Promise.all([promise]).then(() => {
        this.getCode();
      });
    }

  },
};
</script>


